import React, { Component } from 'react';
import NavMenu from '../../components/NavMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GameSparksService from '../../services/GameSparksService';
import PopUpModal from '../../components/PopUpModal';
import Loader from '../../components/Loader';
import AutoSuggestInput from '../../components/AutoSuggestInput';
import ReactTooltip from 'react-tooltip'

// Resources
import '../../index.css'; // Global
//import logo from '../../resources/logo.png';
import logo from '../../resources/updated_prospectus_logo.jpg'
import prospectus_logo from '../../resources/prospectus_logo.png';

export default class Accounts extends Component {

    constructor(props) {
        super();
        this.state = {
            currentUser: null,
            accounts: [],
            admins: [],
            filteredAccounts: [],
            filteredAdmins: [],
            allCompanies: [],
            sectors: [],
            selectedAccount: null,
            loadingRequest: false,
            deleteAccountModal: false,
            createAdminModal: false,
            createUserModal: false,
            resetTokenModal: false,
            userFormIsManager: false,
            userFormUsername: '',
            userFormCompany: '',
            userFormBranch: '',
            userFormSector: '',
            userFormExistingCompany: false,
            adminFormUsername: '',
            adminListFilter: '',
            userListFilter: ''
        }
        this.props = props;

        this.deleteAccount = this.deleteAccount.bind(this);
        this.userAccountPrompt = this.userAccountPrompt.bind(this);
        this.adminAccountPrompt = this.adminAccountPrompt.bind(this);
    }

    componentWillMount() {
        let {history} = this.props;
        GameSparksService.RedirectIfNotType(history, ['admin'], '/sii-survey/', '/sii-survey/questions');

        this.state.currentUser = GameSparksService.GetUser();
        if(this.state.currentUser !== null) {
            this.setState({
                allCompanies: this.state.currentUser.allCompanies
            })
        }
        this.updateAllLists();
    }

    render() {
        let isLoading = this.state.loadingRequest;
        
        // Account List
        let accountList = this.state.filteredAccounts.map((account, index) => (
            <tr key={index}>
                <td>{account.userName}</td>
                <td>{account.token}</td>
                <td>{account.companyName}</td>
                <td>{account.branch}</td>
                <td>{account.userType}</td>
                <td className="action-btns">
                    <ReactTooltip />
                    <button data-tip="Reset Token" className='edit-user-button' onClick={() => {
                        this.resetTokenPrompt(account.userID);
                    }}> <FontAwesomeIcon icon="redo" /></button>

                    <button data-tip="Delete Credential" className='trash-button' onClick={() => {
                        this.deletePrompt(account.userID);
                    }}> <FontAwesomeIcon icon="trash-alt" /></button>
                </td>
            </tr>
        ));
                
        let adminList = this.state.filteredAdmins.map((account, index) => (
            <tr key={index}>
                <td>{account.userName}</td>
                <td>{account.token}</td>
                <td>{account.userType}</td>
                <td></td>
                <td>
                    <button className={this.state.currentUser.userId !== account.userID ? 'trash-button' : 'hidden'} onClick={() => {
                        this.deletePrompt(account.userID);
                    }}>
                    <FontAwesomeIcon icon="trash-alt" /></button>
                </td>
            </tr>
        ));

        let sectorsDropdown = this.state.sectors.map((sector, index) => (
            <option className="sector-option" key={index} value={sector}>{sector}</option>
        ));

        return (
            <div>
                {/* DELETE MODAL */}
                <PopUpModal isActive={this.state.deleteAccountModal}>
                    <p>Are you sure you want to delete this credential?</p>
                    <div className='modal-options'>
                        <button onClick={() => {
                            this.deleteAccount(this.state.selectedAccount);
                        }}><span className={this.state.loadingRequest ? 'hidden': ''}>Delete</span><Loader active={this.state.loadingRequest} type='white' /></button>

                        <button onClick={() => { 
                            this.setState({
                                deleteAccountModal: false,
                                selectedAccount: null
                            })
                        }}>Cancel</button>

                    </div>
                </PopUpModal>

                {/* RESET TOKEN MODAL */}
                <PopUpModal isActive={this.state.resetTokenModal}>
                    <p>Are you sure you want to reset this token?</p>
                    <div className='modal-options'>
                        <button onClick={() => {
                            this.resetToken(this.state.selectedAccount);
                        }}><span className={this.state.loadingRequest ? 'hidden': ''}>Reset Token</span><Loader active={this.state.loadingRequest} type='white' /></button>

                        <button onClick={() => { 
                            this.setState({
                                resetTokenModal: false,
                                selectedAccount: null
                            })
                        }}>Cancel</button>

                    </div>
                </PopUpModal>

                

                {/* CREATE ADMIN MODAL */}
                <PopUpModal isActive={this.state.createAdminModal}>
                    <p>Enter credentials for Admin</p>

                    <input type='text' placeholder='Username' value={this.state.adminFormUsername} onChange={(e)=>{
                        this.setState({
                            adminFormUsername: e.target.value
                        })
                    }}></input>

                    <div className='modal-options'>

                        <button onClick={() => {this.createUserAccount(this.state.adminFormUsername, null, false, true)}}> 
                            <span className='submit-label'>{!isLoading ? 'Create' : ''}</span>  
                            <Loader active={isLoading} type='white' />
                        </button>

                        <button onClick={() => { 
                            this.setState({
                                createAdminModal: false,
                                adminFormUsername: ''
                            })

                        }}>Cancel</button>
                    </div>
                </PopUpModal>

                


                {/* CREATE USER MODAL */}
                <PopUpModal isActive={this.state.createUserModal}>
                    <p>Enter credentials for new user</p>

                    <input type='text' placeholder='Username' value={this.state.userFormUsername} onChange={(e)=>{
                        this.setState({
                            userFormUsername: e.target.value
                        })
                    }} />


                    <AutoSuggestInput all={this.state.allCompanies} keyMatch='name' placeholder='Company' value={this.state.userFormCompany} onChange={(value) => {
                        this.setState({
                            userFormCompany: value
                        })
                    }}/>
                    
                    {/* <AutoSuggestInput all={this.distinctFromObj(this.state.allCompanies, 'sector')} keyMatch='sector' placeholder='Sector' value={this.state.userFormCompany} onChange={(value) => {
                        this.setState({
                            userFormSector: value
                        })
                    }}/> */}

                    {/* Sector Dropdown */}
                    <select className="sector-dropdown" value={this.state.userFormSector} onChange={(event) => {
                        this.setState({
                            userFormSector: event.target.value
                        })
                    }}>
                        <option className="sector-option" key='500' value=''>Company Sector</option>
                        {sectorsDropdown}
                    </select>
                    

                    <input type='text' placeholder='Branch' value={this.state.userFormBranch} onChange={(e)=>{
                        this.setState({
                            userFormBranch: e.target.value
                        })
                    }} />

                    <div className='toggle-group'>
                        <span>User Type</span>

                        <div className='toggle'>
                            <input onChange={(e) => {
                                this.setState({
                                    userFormIsManager: e.target.checked
                                })
                            }} type='checkbox' id='manager-switch'/><label htmlFor="manager-switch">Toggle</label>
                            <span className='left-choice'>Manager</span>
                            <span className='right-choice'>User</span>
                        </div>
                        
                    </div>

                    <div className='modal-options'>

                        <button onClick={() => {this.createUserAccount(this.state.userFormUsername, this.state.userFormBranch, this.state.userFormIsManager, false)}}> 
                            <span className='submit-label'>{!isLoading ? 'Create' : ''}</span>  
                            <Loader active={isLoading} type='white' />
                        </button>

                        <button onClick={() => {
                            this.setState({
                                createUserModal: false,
                                userFormUsername: '',
                                // userFormCompany: '', // Causing issues with state updating in field value
                                userFormSector: '',
                                userFormBranch: ''
                            });
                        }}>Cancel</button>

                    </div>
                </PopUpModal>


                {/* SIDEBAR */}
                <div className='sidebar sidebar-admin'>
                    <img src={logo} className="sidebar-logo" />

                    <div className='sidebar-content'>
                        <NavMenu />
                        <img src={prospectus_logo} className="sidebar-logo-bottom" />
                    </div>
                </div>

                <div className='main-content'>
                    <h1 className='graph-heading'>Accounts</h1>

                    <h2>Administrators</h2>
                    
                    <button className="dashboard-button" onClick={this.adminAccountPrompt}>Create New Admin</button>

                    <input type='text' className='filter-input' placeholder='Filter' value={this.state.adminListFilter} onChange={(e)=>{
                        this.setState({
                            adminListFilter: e.target.value
                        },
                        this.filterAdminList)
                    }} />

                    <table className='accounts-table'>
                        <tbody>
                            <tr>
                                <th>Username</th>
                                <th>Token</th>
                                <th>Type</th>
                                <th></th>
                                <th></th>
                            </tr>
                            
                            <tr className={this.state.admins.length > 0 ? 'hidden' : ''}>
                                <td> <Loader active={true} /> </td>
                                <td> <Loader active={true} /> </td>
                                <td> <Loader active={true} /> </td>
                                <td> <Loader active={true} /> </td>
                            </tr>

                            {adminList}
                        </tbody>
                    </table>

                    <h2>User & Managers</h2>
                    
                    <button className="dashboard-button" onClick={this.userAccountPrompt}>Create New Account</button>

                    <input type='text' className='filter-input' placeholder='Filter' value={this.state.userListFilter} onChange={(e)=>{
                        this.setState({
                            userListFilter: e.target.value
                        },
                        this.filterUserList)
                    }} />

                    <table className='accounts-table'>
                        <tbody>
                            <tr>
                                <th>Username</th>
                                <th>Token</th>
                                <th>Company</th>
                                <th>Branch</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                            
                            <tr className={this.state.accounts.length > 0 ? 'hidden' : ''}>
                                <td> <Loader active={true} /> </td>
                                <td> <Loader active={true} /> </td>
                                <td> <Loader active={true} /> </td>
                                <td> <Loader active={true} /> </td>
                                <td> <Loader active={true} /> </td>
                            </tr>

                            {accountList}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    filterUserList() {
        let filtered = this.state.accounts.filter((account) => {
            if(account.userName !== undefined && account.companyName !== undefined) {
                let lowerUsername = account.userName.toLowerCase();
                let lowerCompany = account.companyName.toLowerCase();
                let lowerFilter = this.state.userListFilter.toLowerCase();

                if(lowerUsername.indexOf(lowerFilter) >= 0 || lowerCompany.indexOf(lowerFilter) >= 0) {
                    return true;
                }
            }
            return false;
        });

        this.setState({
            filteredAccounts: filtered
        });
    }

    filterAdminList() {
        let filtered = this.state.admins.filter((account) => {
            if(account.userName !== undefined) {
                let lowerUsername = account.userName.toLowerCase();
                let lowerFilter = this.state.adminListFilter.toLowerCase();

                if(lowerUsername.indexOf(lowerFilter) >= 0) {
                    return true;
                }
            }
            return false;
        });

        this.setState({
            filteredAdmins: filtered
        });
    }

    updateAllLists() {
        this.updateCompanies();
        this.updateAccountList();
        this.updateAdminList();

        GameSparksService.GetSectors((sectors) => {
            if(sectors.scriptData !== undefined) {
                this.state.sectors = sectors.scriptData.sectors;
            }
        })

        
    }

    updateCompanies() {
        GameSparksService.GetCompanyNames((names) => {
            
            if(names.scriptData !== undefined) {
                let companyNames = names.scriptData.companyNames;
                let newList = [];
                
                companyNames.forEach((name) => {
                    newList.push({name: name});
                });

                this.setState({
                    allCompanies: newList
                },
                () => {console.log(this.state.allCompanies)})
            }
        })
    }

    resetAdminFilter() {
        this.setState({
            adminListFilter: '',
            filteredAdmins: this.state.admins
        });
    }

    resetUserFilter() {
        this.setState({
            userListFilter: '',
            filteredAccounts: this.state.accounts
        });
    }

    updateAccountList(query=null) {
        this.setState({accounts: []});
        GameSparksService.GetCredentialsList(query, 'user_manager', (response) => {
            if(response.scriptData !== undefined) {
                this.setState({accounts: response.scriptData.dataSet.list}, this.resetUserFilter)
            }
        });
    }

    updateAdminList(query=null) {
        this.setState({admins: []});
        GameSparksService.GetCredentialsList(query, 'admin', (response) => {
            if(response.scriptData !== undefined) {
                this.setState({admins: response.scriptData.dataSet.list}, this.resetAdminFilter)
            }
        });
    }

    deleteAccount(id) {
        if(id !== null && !this.state.loadingRequest) {
            this.setState({
                loadingRequest: true
            });

            GameSparksService.UpdateCredential(id, 'delete', (response) => {
                this.setState({
                    loadingRequest: false,
                    deleteAccountModal: false
                });
                this.updateAccountList();
                this.updateAdminList();
            });
        }
    }

    resetToken(id) {
        if(id !== null && !this.state.loadingRequest) {
            this.setState({
                loadingRequest: true
            });

            GameSparksService.UpdateCredential(id, 'resetToken', (response) => {
                this.setState({
                    loadingRequest: false,
                    resetTokenModal: false
                });
                this.updateAccountList();
                this.updateAdminList();
            });
        }
    }

    createUserAccount(username, branch="", isManager, adminOverride) {
        let accType = isManager ? 'manager' : 'user';
        accType = adminOverride ? 'admin' : accType;
        // let companyName = GameSparksService.GetUser();
        let company = {
            name: this.state.userFormCompany,
            sector: this.state.userFormSector
        };

        if(this.isValidValue(username) && !this.state.loadingRequest) {
            
            this.setState({
                loadingRequest: true
            });

            GameSparksService.CreateUserCredential(username, accType, company, branch, (response) => {

                this.setState({
                    loadingRequest: false,
                    createUserModal: false,
                    userFormUsername: '',
                    // userFormCompany: '', // Causing issues with state updating in field value
                    userFormSector: '',
                    userFormBranch: ''
                });


                this.updateCompanies();
                this.updateAccountList();
                this.updateAdminList();
            });
        }
    }


    // PROMPTS & MODALS
    deletePrompt(id) {
        if(!this.state.loadingRequest) {
            this.setState({
                selectedAccount: id,
                deleteAccountModal: true
            });
        }
    }

    userAccountPrompt() {
        this.setState({
            createUserModal: true
        })
    }

    adminAccountPrompt() {
        this.setState({
            createAdminModal: true
        })
    }

    resetTokenPrompt(id) {
        if(!this.state.loadingRequest) {
            this.setState({
                selectedAccount: id,
                resetTokenModal: true
            });
        }
    }

    isValidValue(param) {
        if(param !== null && param !== undefined && param !== '') {
            return true;
        }
        return false;
    }

    distinctFromObj(objects, key) {
        if(objects !== undefined) {
            let distinct = [];
            objects.forEach((obj) => {
                let compare = obj[key];
                let isDistinct = true;
                for (let i = 0; i < distinct.length; i++) {
                    if(compare === distinct[i][key]) isDistinct = false;
                }
                if(isDistinct) distinct.push(obj);
            });

            return distinct;
        }
        return [];
    }



}
