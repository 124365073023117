import React, { Component } from 'react';
import Answer from './Answer/Answer';

export default class Question extends Component {

    constructor(props) {
        super();
        this.state = {
            currentChoice: -1,
            multiple: []
        }
        this.props = props;
        this.letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S'];
        this.onChoice = this.onChoice.bind(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.type = props.type
    }

    render() {
        //console.log('Question', this.props)
        return (
            <div className='question'>
                <p className='question-text'>{this.props.question}</p>

                {this.props.options.map((option, i) => {
                    return (<Answer 
                        key={i} 
                        index={i}
                        active={this.isActive(i)}
                        onChoice={this.onChoice} 
                        letter={this.letters[i]} 
                        score={option.score} 
                        group={this.props.group} 
                        answer={option.text}
                        type={this.type}
                    />);
                })}

                <button className='question-button' onClick={() => { 
                    this.submitAnswer();
                }}>Next</button>
            </div>
        );
    }

    isActive(idx) {
      if(!this.type) {
        return this.state.currentChoice === idx ? true : false
      } else if('sdg' === this.type) {
        return this.state.multiple.includes(idx)
      } else if('gender' == this.type) {
        return this.state.currentChoice === idx ? true : false
      }
    }

    onChoice(index) {
        if(!this.type) {
          this.setState({
              currentChoice: index
          })
        } else {
          switch(this.type) {
            case 'sdg':
              if(this.state.multiple.includes(index)) {
                  const edx = this.state.multiple.findIndex(x => x === index)
                  const nmult  = [ ...this.state.multiple ]
                  nmult.splice(edx, 1)
                  this.setState({
                    multiple: [...nmult ]
                  })
              } else {
                if(this.state.multiple.length < 3) {
                  this.setState({
                    multiple: [ ...this.state.multiple, index ]
                  })
                }
              }
            break
            case 'gender':
              this.setState({
                currentChoice: index
              })
            break
          }
        }
    }

    submitAnswer() {
        let index = this.state.currentChoice;
        if(!this.type && 'demographics' !== this.props.group) {
          if(index >= 0) {
              let options = this.props.options[index];

              // Conditional skipping - TODO Needs refactoring if the process gets more complicated.
              // For now only handles Yes/No answer set
              let skipTo = null;
              if(index === 0 && this.props.skipYes) skipTo = this.props.skipYes;
              if(index === 1 && this.props.skipNo) skipTo = this.props.skipNo;
              

              //console.log('Question :: Submitting Question', this.props.questionIndex, this.props.group, options.score);
              this.props.onAnswer(this.props.questionIndex, this.props.group, options.score, skipTo);
          }
        } else if('sdg' == this.type) {
          const opts = this.props.options
          //console.log('submit SDG', opts)
          let skipTo = null;
          if(index === 0 && this.props.skipYes) skipTo = this.props.skipYes;
          if(index === 1 && this.props.skipNo) skipTo = this.props.skipNo;
          this.props.onAnswer(this.props.questionIndex, this.props.group, this.state.multiple, skipTo);

        } else if('gender' === this.type)  {

          const opts = this.props.options
          //console.log('submit GENDER', opts)
          let skipTo = null;
          if(index === 0 && this.props.skipYes) skipTo = this.props.skipYes;
          if(index === 1 && this.props.skipNo) skipTo = this.props.skipNo;
          this.props.onAnswer(this.props.questionIndex, this.props.group, index, skipTo);

        } else if('demographics' === this.props.group) {
          const opts = this.props.options
          //console.log('submit DEMOGRAPHICS', opts)
          let skipTo = null;
          if(index === 0 && this.props.skipYes) skipTo = this.props.skipYes;
          if(index === 1 && this.props.skipNo) skipTo = this.props.skipNo;
          this.props.onAnswer(this.props.questionIndex, this.props.group, index, skipTo);

        }
    }
}
