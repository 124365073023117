import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import GameSparksService from '../../services/GameSparksService';

// Styling
import '../../index.css'; // Global
import './Login.css'; // Local

// Resources
//import logo from '../../resources/logo.png';
import logo from '../../resources/updated_prospectus_logo.jpg'

export default class Login extends Component {

    constructor(props) {
        super();
        this.state = {
            username: '',
            password: '',
            // username: 'rod2@bxstudio.co.za',
            // password: '123xyz',
            error: ''
        }
        this.props = props;
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    render() {
        let isLoading = this.props.app.state.isLoading;

        const LoadingIndicator = () => (<div className={!isLoading ? 'hidden' : 'lds-ellipsis'}><div></div><div></div><div></div><div></div></div>)
        const LoginButton = withRouter(({ history }) => (
            <button className="gradient-button" onSubmit={this.onSubmit} onClick={this.onSubmit}> <span className='submit-label'>{!isLoading ? 'Login' : ''}</span>  <LoadingIndicator /></button>
        ));
 
        return (
            <div className='bg-gradient fullscreen'>
                <form className='login-container'>
                    <img className="logo" src={logo} alt='Logo' />

                    <h3>Login with your credentials below</h3>
                    <input type="text" value={this.state.username} onChange={this.onEmailChange} placeholder="Username"/>
                    <input type="password" value={this.state.password} onChange={this.onPasswordChange} placeholder="Password" />
                    <LoginButton/>

                    <div className={this.state.error !== '' ? 'error' : 'hidden'}>{this.state.error}</div>
                </form>
            </div>
        );
    }

    onEmailChange(event) {
        this.setState({username: event.target.value});
    }

    onPasswordChange(event) {
        this.setState({password: event.target.value})
    }

    onSubmit(e) {
        this.setState({
            error: ''
        });

        e.preventDefault();
        if(this.state.username !== ''  && this.state.password !== '') {
            GameSparksService.Authenticate(this.state.username, this.state.password, (response) => {
                console.log('AUTH ', response)
                if(response.error === undefined) {
                    // this.props.history.push('/sii-survey/dashboard');
                    this.props.history.push('/sii-survey/accounts');
                } else {
                    switch(response.error.DETAILS) {
                        case 'UNRECOGNISED':
                            this.setState({error: 'Invalid Username or Password'});
                            break;
                        case 'LOCKED':
                            this.setState({error: 'Account Locked. Please wait a while before logging in again.'});
                            break;
                        default:
                            this.setState({error: 'Something went wrong. Please try logging in again.'});
                    }
                }

            });
        } else {
            // Show error on form
            this.setState({
                error: 'Please enter a valid username and password'
            });
        }
    }

}

