import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GameSparksService from '../services/GameSparksService';

// Styling
import '../index.css'; // Global

export default class NavMenu extends Component {

    render() {
        if(GameSparksService.IsUserType('admin')) {
            return (
                <div className='menu'>
                    {/* <NavLink exact to='/sii-survey/dashboard'><FontAwesomeIcon icon="columns" /> Dashboard</NavLink> */}
                    <NavLink to='/sii-survey/accounts'><FontAwesomeIcon icon="users" /> Accounts</NavLink>
                    <NavLink to='/sii-survey/reporting'><FontAwesomeIcon icon="tachometer-alt" /> Reporting</NavLink>
                    <NavLink exact to='/sii-survey/'><FontAwesomeIcon icon="sign-out-alt" /> Logout</NavLink>
                </div>
            )
        } else if(GameSparksService.IsUserType('manager')) {
            return (
                <div className='menu'>
                    <NavLink to='/sii-survey/questions/'><FontAwesomeIcon icon="tachometer-alt" /> Survey</NavLink>
                    <NavLink to='/sii-survey/reporting'><FontAwesomeIcon icon="tachometer-alt" /> Reporting</NavLink>
                    <NavLink exact to='/sii-survey/'><FontAwesomeIcon icon="sign-out-alt" /> Logout</NavLink>
                </div>
            )
        }  else {
            return (
                <div className='menu'>
                    <NavLink exact to='/sii-survey/'><FontAwesomeIcon icon="sign-out-alt" /> Logout</NavLink>
                </div>
            )
        } 
        
    }
}