import React, { Component } from 'react';
// Styling
import '../index.css'; // Global

export default class PopUpModal extends Component {

    constructor(props) {
        super();
        this.state = {
            active: props.active
        }
        this.props = props;
    }

    render() {
        return(
            <div className={this.state.active ? '' : 'hidden'}>
                <div className={this.props.type === 'white' ? 'loader lds-ellipsis' : 'loader lds-ellipsis-lg'}><div></div><div></div><div></div><div></div></div>
            </div>
            )
    }

    setActive(isActive) {
        this.setState({active: false})
    }

}