import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import GameSparksService from '../services/GameSparksService';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPoll, faSignOutAlt, faTachometerAlt, faColumns, faUsers, faTrashAlt, faShareSquare, faEye, faBuilding, faUserEdit, faRedo, faUpload, faDownload } from '@fortawesome/free-solid-svg-icons'

import Login from './Login/Login';
import Questions from './Questions/Questions';

// Dashboard
import Report from './Dashboard/Report';
import Dashboard from './Dashboard/Dashboard';
import Accounts from './Dashboard/Accounts';
import Reporting from './Dashboard/Reporting';

library.add([faPoll, faSignOutAlt, faTachometerAlt, faColumns, faUsers, faTrashAlt, faShareSquare, faEye, faBuilding, faUserEdit, faRedo, faUpload, faDownload]) // font awesome stuff

export default class App extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: true
        }
        this.onGameSparksInit = this.onGameSparksInit.bind(this);
    }

    componentWillMount() {
        GameSparksService.Logout(()=>{
            GameSparksService.InitGameSparks(this.onGameSparksInit);
        });
    }

    render() {
        return (
            <Router>
                <div>
                    <Route path="/sii-survey/" exact render={(routeProps) => (<Login {...routeProps} app={this}/>)} />
                    <Route path="/sii-survey/dashboard/" component={Dashboard} />
                    <Route path="/sii-survey/accounts/" render={(routeProps) => (<Accounts {...routeProps} app={this}/>)} />
                    <Route path="/sii-survey/reporting/" exact render={(routeProps) => (<Reporting {...routeProps} app={this}/>)} />
                    <Route path="/sii-survey/report/:id/:branchId" render={(routeProps) => (<Report {...routeProps} app={this}/>)} />
                    <Route path="/sii-survey/questions/" render={(routeProps) => (<Questions {...routeProps} app={this}/>)} />
                </div>
            </Router>
        );
    }

    onGameSparksInit() {
        this.setState({isLoading: false})
        console.log('GAMESPARKS INITIALIZED');
    }

}

