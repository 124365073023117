import React, { Component } from 'react';
import NavMenu from '../../components/NavMenu';
import GameSparksService from '../../services/GameSparksService';
import Loader from '../../components/Loader';

// Styling
import '../../index.css'; // Global
import './Questions.css'; // Local
import cog from '../../resources/cog.png';

import QuestionSet from './QuestionSet';

//import logo from '../../resources/logo.png';
import logo from '../../resources/updated_prospectus_logo.jpg'
import prospectus_logo from '../../resources/prospectus_logo.png';

export default class Questions extends Component {

    constructor() {
        super();
        this.state = {
            questionsStarted: false,
            questions: [],
            answerSet: []
        }

        this.getQuestionSet = this.getQuestionSet.bind(this);
    }

    componentWillMount() {
        let {history} = this.props;
        GameSparksService.RedirectIfNotType(history, ['user', 'manager'], '/sii-survey/', null);
        GameSparksService.GetQuestions((result) => {
            console.log('got questions', result)
            if(result.scriptData !== undefined && result.scriptData.questionData !== undefined) {
                
                let answerSet = result.scriptData.questionData.optionText;

                this.setState(
                    {
                        questions: result.scriptData.questionData.questionList,
                        answerSet: this.parseAnswerSet(answerSet)
                    },
                    () => {
                        this.createCategoryIntros();
                    }
                )
            }
            
        })
    }

    render() {
        console.log('Qstate', this.state)
        return (
            <div>
                <div className='sidebar sidebar-gradient'>
                    <img src={logo} className="sidebar-logo" />
                    <div className='sidebar-content'>
                        <h1 className='sidebar-heading'>SII Survey</h1>
                        <NavMenu />
                        <img src={prospectus_logo} className="sidebar-logo-bottom" />
                    </div>
                </div>

                <div className='main-content'>

                    {/* <div className={this.state.questionsStarted ? 'hidden' : 'pre-questions'}>
                        <Loader active={true} />
                    </div> */}
                    
                    {this.getQuestionSet()}
                </div>
            </div>
        );
    }

    createCategoryIntros() {
        for (let i = 0; i < this.state.questions.length; i++) {
            const current = this.state.questions[i];
            const previous = this.state.questions[i-1];
            
            if(i === 0) {
                current.isFirstInCategory = true;
            } else if(current.group !== previous.group) {
                current.isFirstInCategory = true;
            } else {
                current.isFirstInCategory = false;
            }
        }
    }

    getQuestionSet() {
        if(this.state.questionsStarted) {
            return (<QuestionSet ref='questionSet' isActive={this.state.questionsStarted} questions={this.state.questions} answerSets={this.state.answerSet} />)
        } else {
            return (<div className="intro-text">
                <h1>SII Survey</h1>
                <p>Welcome to the Prospectus Corporate Innovation Index survey!</p>

                <p>Thank you for agreeing to take part in this important survey measuring innovation in your organisation. Today we will be asking for your thoughts and opinions in order to assess where your organisation is now from multiple perspectives and how you compare against others. This will help to identify what your organisation needs to do to create and sustain a culture and operating environment that delivers productive innovation.</p>

                <p>The survey should take only 5-7 minutes to complete.</p>

                <p><b>Be assured that all answers you provide are completely anonymous.</b></p>

                <p>Please click ‘Next’ to begin.</p>

                <button className='question-button' onClick={() => { 
                    this.setState({
                        questionsStarted: true
                    });
                }}>Next</button>
            </div>)
        }
    }



    // Parses the choices from GS into a readable list
    parseAnswerSet(gameSparksChoices) {
        let choices = [];
        gameSparksChoices.forEach((data) => {
            choices[data.choices] = data.text;
        });
        return choices;
    }
}

