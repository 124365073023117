import React, { Component } from 'react';
import GameSparksService from '../../services/GameSparksService';
import NavMenu from '../../components/NavMenu';

// Resources
import '../../index.css'; // Global
import logo from '../../resources/logo.png';
import prospectus_logo from '../../resources/prospectus_logo.png';

export default class Dashboard extends Component {

    constructor(props) {
        super();
        this.state = {
            reportId: props.match.params.id
        }
        this.props = props;
    }

    componentWillMount() {
        let {history} = this.props;
        GameSparksService.RedirectIfNotType(history, ['admin'], '/sii-survey/', '/sii-survey/questions');
    }

    render() {
        return (
            <div>
                <div className='sidebar sidebar-admin'>
                    <img alt="" src={logo} className="sidebar-logo" />

                    <div className='sidebar-content'>
                        <NavMenu />
                        <img alt="" src={prospectus_logo} className="sidebar-logo-bottom" />
                    </div>
                </div>

                <div className='main-content'>
                    <h1 className='graph-heading'>Dashboard </h1>

                </div>
            </div>
        );
    }

}


