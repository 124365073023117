import React, { Component } from 'react';

// Styling
import '../index.css'; // Global

export default class PopUpModal extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            active: props.isActive
        }
    }

    render() {
        return(
            <div className={(this.props.isActive ? 'pop-up-modal' : 'pop-up-modal hidden')}>
                <div className='modal-content'>
                    {this.props.children}
                </div>
            </div>
        )
    }

    setActive(isActive) {
        this.setState({active: false})
    }

}