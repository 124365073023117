import React, { Component } from 'react';
const astyle = {
  color: '#5fb4e6',
  'text-decoration': 'none'
}

export default class Answer extends Component {

    constructor(props) {
        super();
        this.state = {}
        this.props = props;
        this.type = props.type
    }

    render() {
        console.log('answer props', this.props.type)
        if(!this.type) {
          return (
            <div onClick={() => {this.props.onChoice(this.props.index)}} className='answer'>
              <div className={'answer-letter ' + (this.props.active ? 'answer-active' : '')}>{this.props.letter}</div>
              <p className='answer-text'>{this.props.answer}</p>
            </div>
          );
        } else if('sdg' == this.type) {
          const it = this.parseAnswer(this.props.answer)
          return (
            <div  className='answer'>
              <div 
                onClick={() => {this.props.onChoice(this.props.index)}}
                className={'answer-letter ' + (this.props.active ? 'answer-active' : '')}
              >
                {this.props.letter}
              </div>
              <p className='answer-text'>
                {it.name}
                <a style={astyle} target="_blank" href={it.url}>
                  {it.sg}
                </a>
              </p>
            </div>
          );
        } else {
          return (
            <div onClick={() => {this.props.onChoice(this.props.index)}} className='answer'>
              <div className={'answer-letter ' + (this.props.active ? 'answer-active' : '')}>{this.props.letter}</div>
              <p className='answer-text'>{this.props.answer}</p>
            </div>
          );
        }
    }

    parseAnswer(a) {
      const p = a.split('|')
      return  {
        sg    : p[0],
        name  : p[1],
        url   : p[2]
      }
    }
}
