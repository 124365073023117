import React, { Component } from 'react';
import NavMenu from '../../components/NavMenu';
import GameSparksService from '../../services/GameSparksService';
import PopUpModal from '../../components/PopUpModal';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip'

// Resources
import '../../index.css'; // Global
//import logo from '../../resources/logo.png';
import logo from '../../resources/updated_prospectus_logo.jpg'
import prospectus_logo from '../../resources/prospectus_logo.png';

export default class Reporting extends Component {

    constructor(props) {
        super();
        console.log('reporting props', props)
        this.state = {
            companies: [],
            branches: [],
            filter: '',
            filteredList: [],
            releaseReportModal: false,
            loadingRequest: false,
            selectedAccount: null
        }
        this.props = props;
        this.releaseReportPrompt = this.releaseReportPrompt.bind(this);
    }

    componentWillMount() {
        let {history} = this.props;
        GameSparksService.RedirectIfNotType(history, ['admin', 'manager'], '/sii-survey/', '/sii-survey/questions');
    }

    componentDidMount() {
        this.getReportData();
    }



    render() {

        let companyList;
        let branchList;

        if(this.state.companies.length > 0) {
            companyList = this.state.filteredList.map((company, index) => {
                // console.log("Checking for branches from", company.companyName, "- Branches:", company.branches)
                if(company.companyName !== undefined) {
                    return (
                        <tr key={index}>
                            <td>{company.companyName}</td>
                            <td>{company.cei}</td>
                            <td>{company.propToAct}</td>
                            <td>{company.practice}</td>
                            <td>{company.culture}</td>
                            <td className='buttons-cell'>
                                <ReactTooltip />
                                <button data-tip="Release" className={company.isReportUnlocked ? 'hidden' : 'release-report-button'} onClick={() => {
                                    this.releaseReportPrompt(company.companyName);
                                }}>
                                <FontAwesomeIcon icon="share-square" /></button>
            
                                <button data-tip="View Report" className='view-report-button' onClick={() => {
                                    this.props.history.push('/sii-survey/report/' + company.companyName + '/null');
                                }}>
                            <FontAwesomeIcon icon="eye" /></button>
                            </td>
                        </tr>
                    );
                }

            });

            this.state.filteredList.map((company, index) => {
                
                if(company.branches.length > 0) {
                    branchList = company.branches.map((branch, branchIndex) => {

                        let newIndex = index+this.state.filteredList.length+branchIndex;
                        
                        if(company.companyName !== undefined) {
                            return (
                                <tr key={newIndex}>
                                    <td>{company.companyName + " (" + branch.branchName + ")"}</td>
                                    <td>{branch.cei}</td>
                                    <td>{branch.propToAct}</td>
                                    <td>{branch.practice}</td>
                                    <td>{branch.culture}</td>
                                    <td className='buttons-cell'>
                                        <ReactTooltip />
                                        <button data-tip="Release" className={branch.isReportUnlocked ? 'hidden' : 'release-report-button'} onClick={() => {
                                            this.releaseReportPrompt(branch.branchName);
                                        }}>
                                        <FontAwesomeIcon icon="share-square" /></button>
                    
                                        <button data-tip="View Report" className='view-report-button' onClick={() => {
                                            this.props.history.push('/sii-survey/report/' + company.companyName +'/'+ branch.branchName);
                                        }}>
                                        <FontAwesomeIcon icon="eye" /></button>
                                    </td>
                                </tr>
                            );
                        }
                        
                    });
                    
                }
            });
        }



        return (
            <div>
                {/* RELEASE REPORT MODAL */}
                <PopUpModal isActive={this.state.releaseReportModal}>
                    <p>Are you sure you want to release this report to managers of this company?</p>
                    
                    <div className='modal-options'>
                        <button onClick={() => {
                            this.releaseReport()
                        }}><span className={this.state.loadingRequest ? 'hidden': ''}>Release Report</span><Loader active={this.state.loadingRequest} type='white' /></button>

                        <button onClick={() => { 
                            this.setState({
                                releaseReportModal: false,
                                selectedAccount: null
                            })
                        }}>Cancel</button>

                    </div>
                </PopUpModal>

                <div className='sidebar sidebar-admin'>
                    <img src={logo} className="sidebar-logo" />

                    <div className='sidebar-content'>
                        <NavMenu />
                        <img src={prospectus_logo} className="sidebar-logo-bottom" />
                    </div>
                </div>

                <div className='main-content'>
                    <h1 className='graph-heading'>Reporting</h1>

                    <div className={this.state.companies.length > 0 ? '' : 'hidden'}>
                        <input type='text' className='filter-input' placeholder='Filter' value={this.state.filter} onChange={(e)=>{
                            this.setState({
                                filter: e.target.value
                            },
                            this.filterList)
                        }} />

                        <table className='accounts-table'>
                            <tbody>
                                <tr>
                                    <th>Company</th>
                                    <th>SII Score</th>
                                    <th>Propensity To Act</th>
                                    <th>Practice</th>
                                    <th>Culture</th>
                                    <th></th>
                                </tr>
                                
                                <tr className={this.state.companies.length > 0 ? 'hidden' : ''}>
                                    <td> <Loader active={true} /> </td>
                                    <td> <Loader active={true} /> </td>
                                    <td> <Loader active={true} /> </td>
                                    <td> <Loader active={true} /> </td>
                                    <td> <Loader active={true} /> </td>
                                </tr>

                                {companyList}
                                {branchList}
                            </tbody>
                        </table>
                    </div>
                    
                    <div className={this.state.companies.length > 0 ? 'hidden' : ''}>
                        <p>No reports have been released yet.</p>
                    </div>
                </div>
            </div>
        );
    }

    releaseReport() {
        if(this.state.selectedAccount !== null) {
            this.setState({loadingRequest: true});
            GameSparksService.ReleaseReport(this.state.selectedAccount, (data) => {
                this.setState({
                    loadingRequest: false,
                    selectedAccount: null,
                    releaseReportModal: false
                },
                () => {
                    this.getReportData();
                })
            })
        }
    }

    releaseReportPrompt(companyName) {
        this.setState({
            selectedAccount: companyName,
            releaseReportModal: true
        })
    }

    getReportData() {
        GameSparksService.GetReportData(null, null, (data) => {
            console.log('GetReportData', data);
            if(data.scriptData !== undefined && data.error === undefined) {

                if(data.scriptData.managerCompanyName !== undefined) {
                    // Is manager
                    let selected;
                    for (let i = 0; i < data.scriptData.companyList.length; i++) {
                        const company = data.scriptData.companyList[i];
                        if(company.isSelected !== undefined && company.isSelected === true) {
                            selected = company;
                        }
                    }

                    if(selected !== undefined) {
                        let selectedCompany = {
                            ...selected,
                            companyName: data.scriptData.managerCompanyName,
                            propToAct: data.scriptData.managerCompanyPropToAct,
                            sector: data.scriptData.managerCompanySector,
                            isReportUnlocked: true
                        }
                        
                        this.setState({
                            companies: [selectedCompany],
                            filteredList: [selectedCompany],
                            filter: ''
                        })
                    }
                    
                } else {
                    this.setState({
                        companies: data.scriptData.companyList,
                        filteredList: data.scriptData.companyList,
                        filter: ''
                    })
                }
                
            }
        })
    }
    
    filterList() {
        console.log(this.state.companies)
        let filtered = this.state.companies.filter((company) => {
            if(company.companyName !== undefined) {
                let companyName = company.companyName.toLowerCase();
                let lowerFilter = this.state.filter.toLowerCase();

                if(companyName.indexOf(lowerFilter) >= 0) {
                    return true;
                }
            }
            return false;
        });

        console.log(this.state.filter, filtered)

        this.setState({
            filteredList: filtered
        });
    }
}
