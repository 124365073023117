import GameSparks from '../vendor/gamesparks';
import CryptoJS from 'crypto-js';

// TESTING
// const gameSparksSecret = 'Xs6mf1XTsCWgsZWVcbdpoxUjXeYMtlWV'; 
// const gameSparksKey = 'q377958vFio5';
// const environment = 'preview';

// DEV
// const gameSparksSecret = 'GM54AZfWzfmL5mJPDFZ8DY9dZRUlbC0q'; 
// const gameSparksKey = 'x380817YcSNZ';
// const environment = 'live';

// PROD
const gameSparksSecret = 'xzFcxpqCd642caN8zDg1h7Ds51LM2CbW'; 
const gameSparksKey = 's380977q57sA';
const environment = 'live';


export default class GameSparksService {

    static instance;

    static InitGameSparks(callback) {
        localStorage.setItem('user', null);
        this.gamesparks = new GameSparks();
        this.instance = this.gamesparks;

        if(!this.IsConnected()) {
            let initData = {
                key: gameSparksKey,
                secret: gameSparksSecret,
                credential: 'device',
                onNonce: onNonce,
                onInit: function () {
                    callback();
                },
                onMessage: (message) => {
                    // console.log('message', message)
                },
                logger: function () {}
            }
    
            if(environment === 'preview') {
                this.gamesparks.initPreview(initData);
            } else {
                this.gamesparks.initLive(initData);
            }
        }
    }

    static IsConnected() {
        if(this.instance.isConnected()) {
            return true;
        }
        return false;
    }

    static Authenticate(username, password, callback) {
        this.instance.authenticationRequest(password, username, (response) => {

            let data = null;
            
            if(response.error === undefined) {
                data = {
                    displayName: response.displayName,
                    type: response.scriptData.userType,
                    company: response.scriptData.companyName,
                    userId: response.userId,
                    allCompanies: response.scriptData.companyList
                }

                localStorage.setItem('user', JSON.stringify(data));
            } else {
                data = {
                    error: response.error
                }
            }
            callback(data);
        });

    }

    static Logout(callback) {
        localStorage.setItem('user', null);
        callback();
    }

    static IsAuthenticated() {
        let user = localStorage.getItem('user');
        if(user !== null && user !== '') {
            return true;
        }
        return false;
    }

    static IsUserType(type) {
        let user = this.GetUser();
        if(user !== null) {
            if(user.type === type) {
                return true;
            }
        }
        
        return false;
    }

    static GetUser() {
        let user = localStorage.getItem('user');
        return JSON.parse(user);
    }

    static RedirectIfNotType(history, type, notAuthenticatedPath, notTypePath) {
        
        if(!this.IsAuthenticated() || !this.IsConnected()) {
            console.log("Redirecting: Not Authenticated");
            history.push(notAuthenticatedPath);
            return;
        }
        
        let isValid = false;
        if(notTypePath !== null) {
            for (let i = 0; i < type.length; i++) {
                if(this.IsUserType(type[i])) {
                    isValid = true;
                } 
            }
            if(!isValid) {
                console.log("Redirecting: Not Valid");
                history.push(notTypePath);
                return;
            }
        }
    }

    static GetCredentialsList(query=null, dataSet, callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'GET_CREDENTIAL_LIST',
                dataSet: dataSet,
                limit: 500,
                skip: 0,
                query : query ? query : {} 
            },
            (response) => {callback(response)}
        );
    }

    static UpdateCredential(id, action, callback) {
        if(isValidValue(id) && isValidValue(action)) {
            this.instance.sendWithData("LogEventRequest",
                {
                    eventKey: 'UPDATE_CREDENTIAL',
                    credentialID: id,
                    action: action,
                    data: null
                },
                (response) => {callback(response)}
            );
        } else {
            callback({error: 'GS Service Error: UpdateCredential(id, action, callback) - Undefined ID or Action'});
        }
        
    }

    static GetSectors(callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'GET_SECTORS'
            },
            (response) => {callback(response)}
        );
    }

    static GetCompanyNames(callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'GET_COMPANY_NAMES'
            },
            (response) => {callback(response)}
        );
    }

    static CreateUserCredential(username, userType, company, branchName, callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'ADD_NEW_CREDENTIAL',
                userName: username,
                userType: userType,
                sector: company.sector !== "" && company.sector !== undefined ? company.sector : "None",
                companyName: company.name,
                branchName: branchName
            },
            (response) => {callback(response)}
        );
    }

    static GetReportData(companyName, branchName, callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'GET_GRAPH_DATA',
                companyName: companyName,
                branchName: branchName
            },
            (response) => {callback(response)}
        );
        
    }

    static GetQuestions(callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'GET_SURVEY_DATA'
            },
            (response) => {callback(response)}
        );
    }

    static SubmitSurvey(answers, callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'SUBMIT_SURVEY',
                questionsList: answers
            },
            (response) => {callback(response)}
        );
    }

    static ReleaseReport(companyName, callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'UNLOCK_REPORT',
                companyName: companyName
            },
            (response) => {callback(response)}
        );
    }

    static GetUploadURL(companyName, branchName, callback) {
        this.instance.sendWithData("GetUploadUrlRequest",
            {
                uploadData: {
                    companyName: companyName,
                    branchName: branchName,
                    type: 'report'
                }
                
            },
            (response) => {callback(response)}
        );
    }

    static GetDownloadReport(companyName, branchName, callback) {
        let branch = branchName !== 'null' ? branchName : 'null';
        
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'GET_UPLOADEDREPORT',
                companyName: companyName,
                branchName: branch
            },
            (response) => {callback(response)}
        );
        
    }

    static GetCSVDownloadReport(companyName, branchName, callback) {
        this.instance.sendWithData("LogEventRequest",
            {
                eventKey: 'GET_REPORT_DATA',
                companyName: companyName,
                branchName: branchName
            },
            (response) => {callback(response)}
        );
        
    }

}

function onNonce(nonce) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(nonce, gameSparksSecret));
}

function isValidValue(param) {
    if(param !== null && param !== undefined && param !== '') {
        return true;
    }
    return false;
}
