import React, { Component } from 'react';
import GameSparksService from '../../services/GameSparksService';
import NavMenu from '../../components/NavMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Graph components
import {Scatter, Bar} from 'react-chartjs-2';
import ReactSpeedometer from "react-d3-speedometer";
import { Progress } from 'react-sweet-progress';
import Config from '../../config.json';
import "react-sweet-progress/lib/style.css";

// Styling
import '../../index.css'; // Global

// File Upload Library
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { DashboardModal } from '@uppy/react'
import XHRUpload from '@uppy/xhr-upload'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

// Resources
//import logo from''../../resources/logo.png';
import logo from '../../resources/updated_prospectus_logo.jpg'
import prospectus_logo from '../../resources/prospectus_logo.png';

export default class Report extends Component {

    constructor(props) {
      super();
      this.state = {
          reportId: props.match.params.id,
          branchId: props.match.params.branchId,
          ceiScore: 0,
          propToAct: 0,
          relativePracticePos: 0,
          relativeCulturePos: 0,
          relativeIntentPos: 0,
          relativeCapacityPos: 0,
          scatterData: [],
          scatterDataSelected: [],
          breakdownData: [0,0,0,0],
          userSurveys: 0,
          managerSurveys: 0,
          uploaderOpen: false,
          uploadURL: null
      }
      this.props = props;

      this.uppy = new Uppy({
          id: 'uppy',
          restrictions: {
              maxFileSize: 35000000,
              maxNumberOfFiles: 1,
              minNumberOfFiles: 1,
              allowedFileTypes: [ 'text/csv' ]
          },
          autoProceed: false,
          debug: true
      });

      this.uppy.use(AwsS3, {
        getUploadParameters (file) {
          //console.log('getUploadParams', file)
          return fetch(`https://bxg-prospectus.com/upload/signurl/put/${file.name}`)
          //return fetch(`https://bxg-prospectus.com/upload/s3-creds/put/${file.name}`)
            .then(async resp => {
              const json = await resp.json()
              console.log('fetched json', json)
              return {
                method: 'PUT',
                url: json.url,
                fields: []
              }
            }).catch(err => {
              console.log('fetch error', err)

            })
        }
      })

      this.uppy.on('complete', (res) => {
        console.log('uppy done', res)


      })



      /*
        this.uppy.on('complete', (result) => {
            
            this.setState({
                uploaderOpen: false
            },
            () => {
                GameSparksService.GetUploadURL(this.state.reportId, this.state.branchId, (response) => {
                    this.uppy.reset();

                    const xhrPlugin = this.uppy.getPlugin('XHRUpload')
                    this.uppy.removePlugin(xhrPlugin);

                    console.log('response', response)
                    const { scriptData } = response


                    this.uppy.use(XHRUpload, { 
                        endpoint: scriptData.url,
                        fieldName: 'file'
                    })
                });
            })
        })
        */

      /*
        GameSparksService.GetUploadURL(this.state.reportId, this.state.branchId, (response) => {
            const { scriptData } = response
            const { url } = scriptData
            console.log('URL', url)
            this.uppy.use(XHRUpload, { 
                endpoint: url,
                fieldName: 'file'
            })
        })

    */
      console.log('Report props', props)
      console.log('Report state', this.state)
    }

    componentWillMount() {
        let {history} = this.props;
        GameSparksService.RedirectIfNotType(history, ['admin', 'manager'], '/sii-survey/', '/sii-survey/questions');
    }

    componentWillUnmount () {
        this.uppy.close()
    }

    componentDidMount() {
        this.getReportData();
    }

    render() {
        let scatterData = {
            datasets: [{
                label: 'You',
                pointBackgroundColor: '#018DDB',
                pointRadius: 6,
                pointHoverRadius: 8,
                data: this.state.scatterDataSelected
            },
            {
                label: 'Competitors',
                pointBackgroundColor: '#8CD13F',
                pointRadius: 6,
                pointHoverRadius: 8,
                data: this.state.scatterData
            }]
        }

        let scatterOptions = {
            legend: {
                display: false
            },
            layout: {
                padding: {
                    right: 50
                }
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return `${tooltipItem.xLabel}, ${tooltipItem.yLabel}`;
                    }
                }
            },
            scales:{
                xAxes: [{
                    ticks: {
                        max: 100,
                        beginAtZero: true
                    },
                    scaleLabel: {
                        fontColor: "#9e9e9e",
                        fontSize: 20,
                        display: true,
                        labelString: 'Propensity To Act'
                    },
                    gridLines: {
                        color: '#eaeaea',
                        zeroLineColor: '#c7c7c7'
                    }
                }],
                yAxes: [{
                    ticks: {
                        max: 100,
                        beginAtZero: true
                    },
                    scaleLabel: {
                        fontColor: "#9e9e9e",
                        fontSize: 20,
                        display: true,
                        labelString: 'Innovative Behaviour'
                    },
                    gridLines: {
                        color: '#eaeaea',
                        zeroLineColor: '#c7c7c7'
                    }
                }],
                
            }
        }

        let breakdownData = {
            labels: ['Culture','Practice','Intent','Capacity'],
            datasets: [{
                data: this.state.breakdownData,
                backgroundColor: [
                    'rgba(68,115,197, 0.75)',
                    'rgba(237,125,49, 0.75)',
                    'rgba(112,174,71, 0.75)',
                    'rgba(112,48,160, 0.75)'
                ]
            }]
        };

        let breakdownOptions = {
            
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 24,
                        fontColor: '#9e9e9e'
                    }
                }],
                yAxes: [{
                    ticks: {
                        max: 10,
                        beginAtZero: true
                    }
                }]
            }
        }

        let uploader = <DashboardModal 
        uppy={this.uppy} 
        open={this.state.uploaderOpen} 
        closeModalOnClickOutside={true}
        onRequestClose={() => {
            this.setState({
                uploaderOpen: false
            })
        }}/>
        
        let downloadReportButton = <span></span>
        let uploadButton = <span></span>;
        if(GameSparksService.IsUserType('admin')) {
            downloadReportButton = <span>
                <button className='download-btn' onClick={()=>{
                    GameSparksService.GetCSVDownloadReport(this.state.reportId, this.state.branchId, (result) => {
                            const element = document.createElement("a");
                            const file = new Blob([result.scriptData.RESPONSE_RAW], {type: 'text/plain'});
                            element.href = URL.createObjectURL(file);
                            let branchStr = this.state.branchId !== "null" ? "_"+this.state.branchId : "";
                            element.download = this.state.reportId+branchStr+"_report.csv";
                            document.body.appendChild(element); // Required for this to work in FireFox
                            element.click();
                        });
                    }}>Download Results</button>
            </span>;

            uploadButton = <span>
                <button className='download-btn' onClick={()=>{
                    this.setState({
                        uploaderOpen: true
                    })
                }}><FontAwesomeIcon icon="upload" /> Upload Report</button>
                <p>Max. Size 3.5MB</p>
            </span>;
        }

        return (
            <div>
                {uploader}
                <div className='sidebar sidebar-admin'>

                    <img src={logo} className="sidebar-logo" />

                    <div className='sidebar-content'>

                        <h1 className='cei-score'>{this.state.ceiScore}</h1>

                        <div className='progress-wrapper'>
                            <Progress 
                                percent={this.state.ceiScore}
                            />

                            <div className='progress-labels'>
                                
                            </div>
                        </div>
                        

                        <p>The above value is determined from multiple scores from the graphs shown.</p>
                        <p><b>{this.state.userSurveys}</b> Users & <b>{this.state.managerSurveys}</b> Managers have completed the survey.</p>

                        
                        <button className='download-btn' onClick={()=>{
                            GameSparksService.GetDownloadReport(this.state.reportId, this.state.branchId, (result) => {
                                console.log(result);
                                if(result.scriptData !== undefined && result.scriptData.downloadUrl !== undefined) {
                                    window.open(result.scriptData.downloadUrl);
                                } else {
                                    alert("This company or branch doesn't have any uploaded reports");
                                }
                                
                            });
                        }}><FontAwesomeIcon icon="download" /> Download Report</button>
                        {uploadButton}
                        {downloadReportButton}
                        <NavMenu />
                        <img src={prospectus_logo} className="sidebar-logo-bottom" />
                    </div>
                </div>

                <div className='main-content'>
                    <h1 className='graph-heading report-heading'><FontAwesomeIcon icon="building" /> {this.state.reportId}</h1>

                    <h1 className='graph-heading'>Entrepreneurship & Innovation <span>Landscape</span></h1>

                    <div className='graph-section'>
                        <div className=''>
                            <Scatter 
                                ref='scatterChart'
                                data={scatterData}
                                options={scatterOptions}
                                height={50}
                                width={100}
                            />
                        </div>
                    </div>

                    <h1 className='graph-heading'>Results <span>Breakdown</span></h1>

                    <div className='graph-section'>
                        <div className='col col-60'>
                            {/* <div className='speedo-wrapper'>
                                <ReactSpeedometer 
                                    width={400}
                                    height={300}
                                    maxValue={10}
                                    value={this.state.propToAct}
                                    needleHeightRatio={0.8}
                                    needleColor="#4a4a4a"
                                    startColor="#ef3030"
                                    segments={10}
                                    endColor="#A4DB1D"
                                />
                            </div> */}
                            <Bar
                                data={breakdownData}
                                width={500}
                                height={300}
                                options={breakdownOptions}
                            />
                        </div>

                        <div className='graph-summary col col-40'>
                            {this.getPracticeHeading()}
                        </div>

                        <div className='graph-summary col col-40'>
                            {this.getCultureHeading()}
                        </div>

                        <div className='graph-summary col col-40'>
                            {this.getIntentHeading()}
                        </div>

                        <div className='graph-summary col col-40'>
                            {this.getCapacityHeading()}
                        </div>
                    </div>

                    
                </div>
            </div>
        );
    }

    getPracticeHeading() {
        if(this.state.relativePracticePos > 0) {
            return (<h1 className='graph-heading'><span className='status green-status'>You're Ahead</span><span>of your competitors for <b>Practice</b></span></h1>)
        } else if(this.state.relativePracticePos < 0) {
            return (<h1 className='graph-heading'><span className='status red-status'>You're Behind</span><span>your competitors for <b>Practice</b></span></h1>)
        } else {
            return (<h1 className='graph-heading'><span className='status yellow-status'>You're Similar</span><span>to your competitors for <b>Practice</b></span></h1>)
        }
    }

    getCultureHeading() {
        if(this.state.relativeCulturePos > 0) {
            return (<h1 className='graph-heading'><span className='status green-status'>You're Ahead</span><span>of your competitors for <b>Culture</b></span></h1>)
        } else if(this.state.relativeCulturePos < 0) {
            return (<h1 className='graph-heading'><span className='status red-status'>You're Behind</span><span>your competitors for <b>Culture</b></span></h1>)
        } else {
            return (<h1 className='graph-heading'><span className='status yellow-status'>You're Similar</span><span>to your competitors for <b>Culture</b></span></h1>)
        }
    }

    getIntentHeading() {
        if(this.state.relativeIntentPos > 0) {
            return (<h1 className='graph-heading'><span className='status green-status'>You're Ahead</span><span>of your competitors for <b>Intent</b></span></h1>)
        } else if(this.state.relativeIntentPos < 0) {
            return (<h1 className='graph-heading'><span className='status red-status'>You're Behind</span><span>your competitors for <b>Intent</b></span></h1>)
        } else {
            return (<h1 className='graph-heading'><span className='status yellow-status'>You're Similar</span><span>to your competitors for <b>Intent</b></span></h1>)
        }
    }

    getCapacityHeading() {
        if(this.state.relativeCapacityPos > 0) {
            return (<h1 className='graph-heading'><span className='status green-status'>You're Ahead</span><span>of your competitors for <b>Capacity</b></span></h1>)
        } else if(this.state.relativeCapacityPos < 0) {
            return (<h1 className='graph-heading'><span className='status red-status'>You're Behind</span><span>your competitors for <b>Capacity</b></span></h1>)
        } else {
            return (<h1 className='graph-heading'><span className='status yellow-status'>You're Similar</span><span>to your competitors for <b>Capacity</b></span></h1>)
        }
    }

    getPropToActHeading() {
        // <h1 className='graph-heading'><span className='status green-status'>You're Ahead</span><span>of your competitors for Culture</span></h1>
        if(this.state.propToAct <= 2.5) {
            return (<h1 className='graph-heading'><span className='status red-status'>Low</span><span>Propensity To Act</span></h1>)
        } else if(this.state.propToAct <= 5) {
            return (<h1 className='graph-heading'><span className='status orange-status'>Moderate</span><span>Propensity To Act</span></h1>)
        } else if(this.state.propToAct <= 7.5) {
            return (<h1 className='graph-heading'><span className='status yellow-status'>Good</span><span>Propensity To Act</span></h1>)
        }  else {
            return (<h1 className='graph-heading'><span className='status green-status'>Great</span><span>Propensity To Act</span></h1>)
        }
    }

    getReportData() {
        this.setState({
            scatterData: Config.mockScatterData
        });
        GameSparksService.GetReportData(this.state.reportId, this.state.branchId, (data) => {
                console.log("Get Report Data - REPORT", data)
                if(data.scriptData !== undefined) {
                    let companyList = data.scriptData.companyList;
                  console.log('companyList', companyList)
    
                    let selected = null;
                    let selectedData = null;
                    let companies = [];
                    let ceiScore = 0;
                    let propToAct = 0;
    
                    let highestPracticePos = 0;
                    let lowestPracticePos = 100;
                    let highestCulturePos = 0;
                    let lowestCulturePos = 100;
                    let highestIntentPos = 0;
                    let lowestIntentPos = 100;
                    let highestCapacityPos = 0;
                    let lowestCapacityPos = 100;
                    
                    if(this.state.branchId === null || this.state.branchId === undefined || this.state.branchId === 'null') {
                        for (let i = 0; i < companyList.length; i++) {
                            const company = companyList[i];
                            
                            if(company.isSelected !== undefined) {
                                selectedData = company;
                                selected = [{x: company.propToAct, y: company.entSnap}];
                                ceiScore = company.cei;
                                propToAct = company.propToAct;
                            } else {
                                companies.push({x: company.propToAct, y: company.entSnap});
        
                                // Determine if they're ahead, behind or inbetween competitors
                                highestPracticePos = company.practice > highestPracticePos ? company.practice : highestPracticePos;
                                lowestPracticePos = company.practice < lowestPracticePos ? company.practice : lowestPracticePos;
                                highestCulturePos = company.culture > highestCulturePos ? company.culture : highestCulturePos;
                                lowestCulturePos = company.culture < lowestCulturePos ? company.culture : lowestCulturePos;
                                highestIntentPos = company.intent > highestIntentPos ? company.intent : highestIntentPos;
                                lowestIntentPos = company.intent < lowestIntentPos ? company.intent : lowestIntentPos;
                                highestCapacityPos = company.capacity > highestCapacityPos ? company.capacity : highestCapacityPos;
                                lowestCapacityPos = company.capacity < lowestCapacityPos ? company.capacity : lowestCapacityPos;
                            }

                        }
                      console.log('companies', companies, 'selected', selected)
                    } else {
                        for (let i = 0; i < companyList.length; i++) {

                            const company = companyList[i];
                            if(company.companyName == this.state.reportId && company.branches.length > 0) {
                                for (let j = 0; j < company.branches.length; j++) {
                                    const branch = company.branches[j];

                                  console.log('branch', branch)
                                    if(branch.branchName === this.state.branchId) {
                                        selectedData = branch;
                                        selected = [{x: branch.propToAct, y: branch.entSnap}];
                                        ceiScore = branch.cei;
                                        propToAct = branch.propToAct;
                                    } else {
                                        companies.push({x: branch.practice, y: branch.culture});
                                        //companies.push({x: branch.propToAct, y: branch.es});
                
                                        // Determine if they're ahead, behind or inbetween competitors
                                        highestPracticePos = branch.practice > highestPracticePos ? branch.practice : highestPracticePos;
                                        lowestPracticePos = branch.practice < lowestPracticePos ? branch.practice : lowestPracticePos;
                                        highestCulturePos = branch.culture > highestCulturePos ? branch.culture : highestCulturePos;
                                        lowestCulturePos = branch.culture < lowestCulturePos ? branch.culture : lowestCulturePos;
                                        highestIntentPos = company.intent > highestIntentPos ? company.intent : highestIntentPos;
                                        lowestIntentPos = company.intent < lowestIntentPos ? company.intent : lowestIntentPos;
                                        highestCapacityPos = company.capacity > highestCapacityPos ? company.capacity : highestCapacityPos;
                                        lowestCapacityPos = company.capacity < lowestCapacityPos ? company.capacity : lowestCapacityPos;

                                    }
                                }
                            }
                            
                        }
                      console.log('BRANCH companies', companies, 'selected', selected)
                    }
                    
    
                    if(selectedData !== null) {
                        let user = selectedData.submittedCountUser;
                        let manager = selectedData.submittedCountManager;
                        // Set bar graph data
                        this.setState({
                            breakdownData: [selectedData.culture, selectedData.practice,selectedData.intent,selectedData.capacity],
                            userSurveys: user !== undefined && user !== null ? user : 0,
                            managerSurveys: manager !== undefined && manager !== null ? manager : 0
                        })


                        let relPracPos = 0;
                        let relCultPos = 0;
                        let relIntPos = 0;
                        let relCapPos = 0;
    
                        if(selectedData.practice < lowestPracticePos) relPracPos = -1;
                        if(selectedData.practice > highestPracticePos) relPracPos = 1;
    
                        if(selectedData.culture < lowestCulturePos) relCultPos = -1;
                        if(selectedData.culture > highestCulturePos) relCultPos = 1;

                        if(selectedData.intent < lowestIntentPos) relIntPos = -1;
                        if(selectedData.intent > highestIntentPos) relIntPos = 1;
                    
                        if(selectedData.capacity < lowestCapacityPos) relCapPos = -1;
                        if(selectedData.capacity > highestCapacityPos) relCapPos = 1;
    
                        console.log('PRACTICE:',selectedData.practice, lowestPracticePos, highestPracticePos, relPracPos);
                        console.log('CULTURE:',selectedData.culture, lowestCulturePos, highestCulturePos, relCultPos);
                        console.log('INTENT:',selectedData.intent, lowestIntentPos, highestIntentPos, relIntPos);
                        console.log('CAPACITY:',selectedData.capacity, lowestCapacityPos, highestCapacityPos, relCapPos);

                        this.setState({
                            scatterData: companies,
                            scatterDataSelected: selected,
                            propToAct: propToAct,
                            relativePracticePos: relPracPos,
                            relativeCulturePos: relCultPos,
                            relativeIntentPos: relIntPos,
                            relativeCapacityPos: relCapPos,
                            ceiScore: Math.ceil(ceiScore)
                        });
                    } else {
                        //redirect
                        this.props.history.push('/sii-survey/reporting');
                    }
                }
            
            
        })
    }
}

