import React, { Component } from 'react';
import Question from './Question';
import GameSparksService from '../../services/GameSparksService';
import Loader from '../../components/Loader';

export default class QuestionSet extends Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            total: this.props.questions.length,
            current: 0,
            answeredIndexes: [],
            currentScores: [],
            skipQuestions: [],
            isSubmitting: false,
            isThanking: false
        }
        this.getQuestion = this.getQuestion.bind(this);
        this.onAnswer = this.onAnswer.bind(this);
    }

    render() {
        let isComplete = this.state.current >= this.state.total+1;
        let isActive = this.props.isActive;
        
        return (
            <div>
                <div className={'question-set ' + (isComplete || !isActive ? 'hidden' : '')}>
                    <p className='question-progress'>{this.state.current+1} / {this.state.total}</p>
                    
                        {this.getQuestion(this.state.current)}

                    </div>

                
                    <div className={!this.state.isSubmitting || this.state.isThanking? 'hidden' : 'pre-questions'}>
                        <h1 className='center-heading'>Submitting survey results...</h1>
                        <Loader active={true} />
                    </div>

                    <div className={!this.state.isThanking ? 'hidden' : 'pre-questions'}>
                        <h1 className='center-heading'>Thank you for taking the time to fill in the survey!</h1>
                    </div>
            </div>
            
        );
    }

    onAnswer(index, group, score, skipTo=null) {
        //console.log('this.onAnswer', index)
        if(this.state.answeredIndexes[index] !== true) {
            // Compile list of which questions have been answered
            let newIndexes = this.state.answeredIndexes;
            newIndexes[index] = true;

            // Add score to list for API submission
            let scores = this.state.currentScores;
            scores.push(score);

            // Skip certain questions
            let goTo = (skipTo !== undefined && skipTo !== null) ? skipTo : (index+1);
            let skippable = goTo - index-1;
            
            this.setState({
                answeredIndexes: newIndexes,
                current: (index+1),
                currentScores: scores
            },
            () => {
                this.skipQuestions(this.state.current, skippable);
            })
            //console.log('onAnswer', this.state)
        }
    }

    skipQuestions(startIndex, amount) {
        if(amount > 0) {
            //console.log('Skipping Question', this.state.currentScores)
            for (let i = 0; i < amount; i++) {
                this.onAnswer(startIndex+i, 'skipped', 0);
            }
        }
    }

    getQuestion(index) {
        let isComplete = this.state.current >= this.state.total;

        if(!isComplete && this.props.questions[index].isFirstInCategory) {
            return (
                <div className="category-intro">
                    <h1>{renderCategory(this.props.questions[index].group)}</h1>
                    
                    <button className='question-button' onClick={() => { 
                        this.props.questions[index].isFirstInCategory = false;
                        this.setState({
                            current: index
                        })
                    }}>Begin</button>
                </div>
            )
        }

        
        if(!isComplete) {
            //console.log('props', this.props.questions[index])

            return (<Question
                key={index}
                questionIndex={index}
                question={this.props.questions[index].questionTxt}
                group={this.props.questions[index].group}
                options={this.props.answerSets[this.props.questions[index].choices]}
                skipYes={this.props.questions[index].ifYes}
                skipNo={this.props.questions[index].ifNo}
                onAnswer={this.onAnswer}
                type={this.props.questions[index].type}
            />)
            
        } else if(!this.state.isSubmitting){
            //Sanity Check
            //console.log('SCORE SANITY CHECK', this.state.currentScores, this.state.total);

            // Submit & Thank you
            this.setState({
                isSubmitting: true
            },
            () => {
              //console.log('SUBMIT', this.state)
                GameSparksService.SubmitSurvey(this.state.currentScores, (result) => {
                    // Show Thank You screen here
                    window.setTimeout(() => {
                        this.setState({
                            isThanking: true
                        })
                    }, 300);
                })
            })
            
        }
        
    }
}

function renderCategory(cat) {
  switch(cat) {
    case 'demographics':
      return "About You"
    default:
      return cat
  }
}
