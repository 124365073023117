import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
// Styling
import '../index.css'; // Global

export default class AutoSuggestInput extends Component {

    constructor(props) {
        super();
        this.state = {
            all: props.all,
            value: '',
            suggestions: []
        }
        this.props = props;
    }

    onChange = (event, { newValue }) => {
        this.setState(
            {value: newValue},
            () => {this.props.onChange(this.state.value)}
        );
    };

        // Autosuggest will call this function every time you need to update suggestions.
        // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

        // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onChange: this.onChange
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />
        )
    }

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
      
        return inputLength === 0 ? [] : this.props.all.filter(result =>
          result[this.props.keyMatch].toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValue = suggestion => suggestion[this.props.keyMatch];

    renderSuggestion = suggestion => {
        return (
            <div>
                {suggestion[this.props.keyMatch]}
            </div>
        );
    };
}